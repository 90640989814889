import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';

import { Product, ProductVariant } from 'entities/product/product';

type Params = {
  variantIds: ProductVariant['id'][];
  page: number;
  pageSize: number;
  platformOs: PlatformOs;
};

type Data = {
  list: Product[];
};

const NAME = '/Reminders/Get';
const GetReminders = createModernEndpoint<Params, Data>(NAME);

export { GetReminders };
