import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { PageHeader } from 'common/components/page-header';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { Reminders } from 'domains/profile/containers/reminders';

const TITLE = 'Back In Stock';

const ProfileRemindersPage: ApplicationPage = () => {
  return (
    <Page {...DEFAULT_AUTHORIZED_PAGE_PROPS}>
      <PageHeader
        title={TITLE}
        hideBackButton
        showBreadcrumbs={false}
        enableMarginBottom
      />
      <Reminders />
    </Page>
  );
};

ProfileRemindersPage.getMeta = () => ({ title: TITLE });

ProfileRemindersPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileRemindersPage;
