import { Hero } from '@swe/shared/ui-kit/components/hero';
import { NoProductsIllustration } from '@swe/shared/ui-kit/components/illustration';
import { Loader } from '@swe/shared/ui-kit/components/loader';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import { ProductCardConnected } from 'common/containers/product-card';

import { useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { QUERY_NAME_VARIANT_IDS } from 'domains/profile/containers/reminders/constants';
import { GetReminders } from 'endpoints/profile/reminders/get-reminders';

const Reminders = () => {
  const query = useRouterQuery();
  const { platformOs } = usePlatformOs();

  const { variantIds } = query;

  const { data, isLoading } = GetReminders.useRequest(
    variantIds
      ? {
          [QUERY_NAME_VARIANT_IDS]: Array.isArray(variantIds) ? variantIds : [variantIds],
          page: 1,
          pageSize: 999,
          platformOs,
        }
      : null,
  );

  const goToCatalog = useCallback(() => Routes.Catalog, []);

  return (
    <div className={styles.root}>
      {isLoading && <Loader centered />}
      {!isLoading &&
        (data?.list.length ? (
          data?.list.map((product, idx) => (
            <ProductCardConnected
              key={idx}
              className={styles.productCard}
              layout="vertical"
              product={product}
              width={340}
              index={idx}
              analyticalItemListId="reminders"
              analyticalItemListName="reminders"
            />
          ))
        ) : (
          <Hero
            className={styles.notFound}
            title="No Products Found"
            illustration={<NoProductsIllustration />}
            content={<span>Time to add some products</span>}
            actions={[{ name: 'shop-now', children: 'Shop Now', onClick: goToCatalog }]}
          />
        ))}
    </div>
  );
};

export { Reminders };
